$(window).on('load',function(){
    $('body').addClass('is-loaded');
});

$(window).on('beforeunload',function(e){
    $('body').removeClass('is-loaded');
});

$('.js-toggleMenu').on('click',function(){
    $('body').toggleClass('is-menu');
});

$('.js-switchMap')
    .on('mouseover',function(){
        var map = $(this).attr('data-map');
        $('.js-map').removeClass('is-all is-vasakronan is-hufvudstaden is-balder');
        $('.js-map').addClass('is-'+map);
    })
    .on('mouseout',function(){
        $('.js-map').removeClass('is-all is-vasakronan is-hufvudstaden is-balder');
    });

$('.js-anchor').on('click',function(){
    var id = $(this).attr('href').substr(1);
    if ( $("#"+id) ) {
        $('html, body').animate({
            scrollTop: $("#"+id).offset().top
        }, 1000);
    }
});

objectFitImages();

function scrollListenerMap(event) {
    $('.js-switchMap').trigger('mouseout');
    $('.js-switchMap:focus').blur();
}

window.addEventListener('scroll', scrollListenerMap, { passive: true });
scrollListenerMap();

$(document).on("submit", ".Section-form", function() {
    var $form = $(this);
    var formData = $form.serialize();
    var $responseMessage = $form.find(".Section-formResponseMessage");
    var $disabledElements = $form
        .find("input, textarea, select, button")
        .not(":disabled");

    $responseMessage.removeClass("is-visible");
    $disabledElements.attr("disabled", true);
    $form.removeClass("is-success is-error").addClass("is-disabled is-loading");

    $.ajax({
        url: $form.attr("action"),
        method: $form.attr("method"),
        data: formData,
        success: function(data) {
            if (data.status !== "success") {
                $form.removeClass("is-disabled");
                $disabledElements.removeAttr("disabled");
            }

            $form.removeClass("is-loading").addClass("is-success");
            $form.html(data.message)
        },
        error: function(data) {
            $form.removeClass("is-disabled is-loading").addClass("is-error");
            $disabledElements.removeAttr("disabled");

            var message =
                data.responseJSON && data.responseJSON.message
                    ? data.responseJSON.message
                    : "Något gick fel, försök igen om en liten stund!";

            $responseMessage.html(message).addClass("is-visible");
        }
    });

    return false;
});

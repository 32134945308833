function scrollListenerSection(event) {

    var scroll = $(window).scrollTop(),
        windowHeight = $(window).outerHeight();

    $('.js-section').each(function(){

        var top = Math.floor($(this).offset().top) - windowHeight/3;
        var bottom = Math.floor((top+$(this).outerHeight(true)));

        if ( scroll >= top && !( bottom <= scroll ) ) {
            $(this).addClass('is-active').siblings('.js-section.is-active').removeClass('is-active');
            return false;
        }

    });

}
window.addEventListener('scroll', scrollListenerSection, { passive: true });
scrollListenerSection();
